@tailwind base;
@tailwind components;
@tailwind utilities;

.neon-button {
  @apply bg-white text-blue-400 px-4 py-2 rounded-lg flex items-center text-xl font-bold shadow-lg;
  transition: all 0.3s ease-in-out;
}

.neon-button:hover {
  @apply text-white bg-blue-500;
  box-shadow: 0 0 10px rgba(0, 0, 255, 0.7), 0 0 20px rgba(0, 0, 255, 0.5),
    0 0 30px rgba(0, 0, 255, 0.3);
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.animate-slide-up {
  animation: slide-up 1s ease-in-out forwards;
  animation-delay: 2s; /* Delay the animation by 2 seconds */
}

/* You can adjust the GIF size and position using Tailwind classes, or you can customize it further in plain CSS */
